<template>
  <v-container>
    <DataTableSSR
      :api-end-point="`/payments/room-user-fees`"
      :headers="headers"
      :instant-load="true"
      :query-params="{ room_user__user: $route.params.user_id }"
    />
  </v-container>
</template>

<script>
import DataTableSSR from "@/components/global/DataTableSSR.vue";
export default {
  components: { DataTableSSR },
  data: () => ({
    headers: [
      {
        text: "Fee",
        value: "room_fee.fee.amount",
      },
      {
        text: "Paid",
        value: "payment_amount",
      },
      {
        text: "Month",
        value: "room_fee.fee_month",
      },
      {
        text: "Year",
        value: "room_fee.year",
      },
    ],
  }),
  mounted() {},
  methods: {},
};
</script>

<style>
</style>